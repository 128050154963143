import {ticketsAPI} from "../api/api";

const SET_EXECUTOR = 'SET_EXECUTOR';
const EXECUTOR_LOADING = 'LOADING';

let initialState = {
    id: null,
    name: null,
    surname: null,
    patronymic: null,
    email: null,
    activations: [],
    loading: false
};

const executorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXECUTOR: {
            return {
                ...state,
                id: action.executor.id,
                name: action.executor.name,
                surname: action.executor.surname,
                patronymic: action.executor.patronymic,
                email: action.executor.email,
                activations: action.executor.activations
            }
        }
        case EXECUTOR_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}

export const getExecutorThunk = (id) => async (dispatch) => {
    let response = await ticketsAPI.getExecutor(id);
    if (response) {
        dispatch(setLoadingAC(true))
        dispatch(setExecutorAC(response))
        dispatch(setLoadingAC(false))
    }
};

const setLoadingAC = status => {
    return {
        type: EXECUTOR_LOADING,
        loading: status
    }
}

const setExecutorAC = (executor) => {
    return {
        type: SET_EXECUTOR,
        executor
    }
}

export default executorReducer;

