import React from 'react'
import {Alert, Modal, Spin} from "antd"
import s from './VariationInfoModal.module.scss'

const VariationInfoModal = props => {
    return <Modal
        title={props.title}
        visible={props.visible}
        onCancel={props.hideModal}
        className={s.customStyle}
        footer={null}
    >
        {
            props.variationDescription ? <>
                <div className={s.modalData} dangerouslySetInnerHTML={{__html: props.variationDescription.text}}/>

                {/*{props.variationDescription.tutorial &&*/}
                {/*    <Alert*/}
                {/*        message="Информация о записи"*/}
                {/*        description=<p dangerouslySetInnerHTML={{ __html: props.variationDescription.tutorial}}></p>*/}
                {/*        type="info"*/}
                {/*        showIcon*/}
                {/*        style={{marginTop: '20px'}}*/}
                {/*    />*/}
                {/*}*/}
            </>
            :
            <div className={s.spinner}>
                <Spin />
            </div>
        }
    </Modal>
}

export default VariationInfoModal