import React, {useState} from 'react'
import {Button, Input, InputNumber, Modal} from "antd";
import {connect} from "react-redux";
import {setUserFee} from "../../../redux/profileReducer";
import {setUserReportTemplate} from "../../../redux/reportReducer";

const ReportTemplateModal = props => {
    const [templateTitle, setTemplateTitle] = useState('')

    const onChange = e => {
        setTemplateTitle(e.target.value)
    }

    const confirm = () => {
        props.setTemplateTitle(props.userId, templateTitle)
        props.updateProfile(props.userId)
        props.handleCancel()
    }

    return <Modal title="Шаблон отчета"
                  visible={props.isModalVisible}
                  onOk={props.handleOk}
                  onCancel={props.handleCancel}
                  className={`report_style`}
                  destroyOnClose={true}
                  footer={null}
    >
        <Input
            defaultValue={props.template}
            onChange={onChange}
        />

        <Button type="primary"
                style={{width: '100%', marginTop: '10px'}}
                onClick={confirm}
        >Изменить шаблон</Button>
    </Modal>
}

export default connect(null, {
    setTemplateTitle: setUserReportTemplate
})(ReportTemplateModal)