import * as axios from 'axios'
import {getToken} from "../redux/authReducer"

const instance = axios.create({
    //baseURL: 'http://127.0.0.1:8000/api/',
    baseURL: 'http://backend.dreambox.by/api/',
    withCredentials: true
})

export const ticketsAPI = {
    getExecutors() {
        return instance.get('executors').then(data => data.data)
    },
    getExecutor(id) {
        return instance.get(`executor/${id}`).then(data => data.data)
    },
    getTickets(page = 1) {
        return instance.get(`tickets?page=${page}`).then(data => data.data)
    },
    getTicket(id) {
        return instance.get(`ticket/${id}`).then(data => data.data)
    },
    togglePayment(id) {
        return instance.post(`ticket/${id}/payment_status`).then(data => data.data)
    },
    activateTicket(id) {
        return instance.post(`ticket/${id}/activate`).then(data => data.data)
    },
    activateTicketSendCode(id, digits) {
        const formData = new FormData()
        formData.append('code', digits)

        return instance.post(`ticket/${id}/activate/send_code`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(data => data.data)
    },
    activateTicketGetAvailableOptions($orderId) {
        return instance.get(`ticket/${$orderId}/activate/order_available_options`).then(data => data.data)
    },
    activateConfirm(id, products) {
        const formData = new FormData()
        formData.append('products', products)

        return instance.post(`ticket/${id}/activate/confirm`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(data => data.data)
    },
    getUser(id) {
        return instance.get(`user/${id}`).then(data => data.data)
    },
    getMyProfile(id) {
        return instance.get(`user/${id}`).then(data => data.data)
    },
    getReport() {
        return instance.get(`/report`).then(data => data.data)
    },
    getReports() {
        return instance.get(`/reports`).then(data => data.data)
    },
    reportTogglePayment(id) {
        return instance.post(`/report/${id}/toggle_payment`).then(data => data.data)
    },
    // redeemTicketSendCode(id, code) {
    //     const formData = new FormData()
    //     formData.append('code', code)
    //
    //     return instance.post(`/ticket/${id}/redeem`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     }).then(data => data.data)
    // },
    redeemTicket(id) {
        return instance.get(`/ticket/${id}/redeem`).then(data => data.data)
    },
    redeemTicketSave(id, variationId) {
        const formData = new FormData()
        formData.append('variation_id', variationId)

        return instance.post(`/ticket/${id}/redeem/activate`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(data => data.data)
    },
    // redeemTicketStepTwo(id, variationId) {
    //     const formData = new FormData()
    //     formData.append('variation_id', variationId)
    //
    //     return instance.post(`/ticket/${id}/redeem_with_sms`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     }).then(data => data.data)
    // },
    // redeemTicketStepThree(id, variationId, code) {
    //     const formData = new FormData()
    //     formData.append('variation_id', variationId)
    //     formData.append('code', code)
    //
    //     return instance.post(`/ticket/${id}/redeem_with_sms/activate`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     }).then(data => data.data)
    // },
    getVariationInfo(prodId, id) {
        return instance.get(`/variation/${prodId}/${id}`).then(data => data.data)
    },
    getReportStatus() {
        return instance.get(`/report/status`).then(data => data.data)
    },
    setUserFee(userId, fee) {
        const formData = new FormData()
        formData.append('fee', fee)

        return instance.post(`/user/${userId}/set_fee`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(data => data.data)
    },
    setUserTemplate(userId, templateTitle) {
        const formData = new FormData()
        formData.append('title', templateTitle)

        return instance.post(`/user/${userId}/report_template`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(data => data.data)
    },
    getProducts() {
        return instance.get(`/products`).then(data => data.data)
    }
}

export const authAPI = {
    login(email, password) {
        return instance.post(`/auth`, { email, password })
    },
    getUser(id) {
        return instance.post(`/user`).then(data => data.data)
    },
    me() {
        return instance.get(`auth/me`)
    },
    logout() {
        return instance.get(`/logout`)
    }
}