import React, {useState} from 'react'
import s from './ActivateTicket.module.scss'
import './ActivateTicket.scss'
import {Descriptions, Button, List, Avatar, Tag, Tooltip, Collapse, Alert} from "antd"
import ActivateModal from "./ActivateModal/ActivateModal"
import RedeemTicket from "./RedeemTicket/RedeemTicket";
import LoginModal from "../Login/LoginModal/LoginModal";
import checkmark from '../../images/checkbox_checkmark.svg'
import { InfoCircleOutlined } from '@ant-design/icons';
import empty from '../../images/empty.svg'
import close from '../../images/close.svg'
import VariationInfoModal from "./VariationInfoModal/VariationInfoModal";
import {variationInfoClear} from "../../redux/ticketReducer";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import ticket_bg from './../../images/gain.png'
import user from '../../images/user.svg'
import time from '../../images/time.svg'
import {Link} from "react-router-dom";
import RegisterModal from "./RegisterModal/RegisterModal";

const ActivateTicket = (props) => {

    // console.log(props.data.product_variations);

    const [showVariationModal, setShowVariationModal] = useState(false)
    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [modalTitle, setModalTitle] = useState()

    const getColor = (category) => {
        if (category === 'earth') {
            return 'linear-gradient(268deg, #002A19, #00795B)'
        } else if (category === 'water') {
            return 'linear-gradient(268deg, #141F21, #117070)'
        } else if (category === 'air') {
            return 'linear-gradient(268deg, #10133a, #2358a7)'
        } else if (category === 'fire') {
            return 'linear-gradient(268deg, #421300, #A3433E)'
        }
    }

    const paymentMethod = props.data.payment_status // If 0 - Не оплачено

    const variationInfo = (prodId, id, title) => {
        setShowVariationModal(true)
        setModalTitle(title)
        props.variationInfo(prodId, id)
    }

    const openRegisterModal = (prodId, id, title) => {
        setShowRegisterModal(true)
        setModalTitle(title)
        props.variationInfo(prodId, id)
    }

    const hideVariationModal = () => {
        setShowVariationModal(false)
        props.variationInfoClear()
    }

    const hideRegisterModal = () => {
        setShowRegisterModal(false)
        props.variationInfoClear()
    }

    const callToPhone = phone => {
        alert(phone)
    }

    const insert = (str, index, value) => {
        return str.substr(0, index) + value + str.substr(index);
    }

    const ticketNumberWithSpaces = props.ticketId ? insert(insert(props.ticketId, 2, ' '),7, ' ') : ''
    const disabledActivationButton = !paymentMethod || props.data.expired ? true : false
    const activationButtonTooltip = disabledActivationButton ? (!paymentMethod ? 'Билет не оплачен' : 'Срок действия билета истек') : null

    return <>
        <RegisterModal
            visible={showRegisterModal}
            hideModal={() => hideRegisterModal()}
            variationDescription={props.variationData}
            title={modalTitle}
        />

        <VariationInfoModal
            visible={showVariationModal}
            hideModal={() => hideVariationModal()}
            variationDescription={props.variationData}
            title={modalTitle}
        />

        <ActivateModal
            data={props.data}
            isModalVisible={props.isModalVisible}
            showModal={props.showModal}
            handleCancel={props.handleCancel}
            activatePhone={props.activateTicketPhone}
            activateTicketSendCode={props.activateTicketSendCode}
            ticketId={props.ticketId}
            getTicket={props.getTicket}
        />

        {props.showLoginModal &&
            <LoginModal
                isModalVisible={props.showLoginModal}
                handleCancel={props.hideLoginModal}
            />
        }

        {props.isShowRedeemModal &&
            <RedeemTicket
                isModalVisible={props.isShowRedeemModal}
                showRedeemModal={props.showRedeemModal}
                hideRedeemModal={props.hideRedeemModal}
                activatePhone={props.activateTicketPhone}
                activateTicketSendCode={props.activateTicketSendCode}
                ticketId={props.ticketId}
                getTicket={props.getTicket}
                activateOptions={props.data.product_variations}
            />
        }

        <div className={s.ticket}>
            <div className={s.ticket_top_line}>
                <div className={s.ticket_top_line_wrapper}>
                    <span>Билет</span>
                    <span>{ticketNumberWithSpaces}</span>
                </div>
            </div>

            <div className={`${s.right_corner}`} style={{background: getColor(props.data.category)}}></div>

            <div className={s.ticket_content}>
                <h1>{props.data.ticket_owner_fullname}</h1>

                <div style={{marginTop: '30px'}}>
                    <div className={`tags ${props.data.category}`}>
                        <div className={"title"}>
                            {/* <h3 id={"title"}>{props.data.ticket_title_short} {props.data.products[0].obuchenie  === true ? <span>Обучение</span> : null}</h3> */}
                            <h3 id={"title"}>{props.data.ticket_title_short ? props.data.ticket_title_short : props.data.ticket_title}</h3>
                        </div>
                        {props.data.product_properties.chelovek &&
                        <div>
                            <span id={"image"}><img src={user}/></span>
                            <span id={"title"}>{props.data.product_properties.chelovek}</span>
                        </div>
                        }
                        {props.data.product_properties.vremya &&
                        <div>
                            <span id={"image"}><img src={time}/></span>
                            <span id={"title"}>{props.data.product_properties.vremya}</span>
                        </div>
                        }
                    </div>
                    {props.data.product_properties.description ? 
                    <Collapse style={{margin: '20px 0px'}} className={`collapse_style ${props.data.category}`}>
                        <CollapsePanel header="Подробнее о программе" key="1">
                            <div className="content" dangerouslySetInnerHTML={{__html: props.data.product_properties.description}}></div>
                        </CollapsePanel>
                    </Collapse>
                    : ''}

                    <ul className={s.activity_list}>
                        {props.data.product_variations.map((item, index) => {
                            const avatar = <Avatar src={checkmark} className={item.disabled ? 'checkmark_disabled' : null} />
                            const title = item.product_type === 'Урок'  && item.product_type === 'Мультисертификат' ? item.ticket_title_short : item.title
                            const disabled = item.disabled
                            const infoButton = item.activated & !item.used ? <Button onClick={() => openRegisterModal(
                                props.data.product_id, item.variation_id, title)}
                                type="primary" shape="round" size={"small"} className={"info_button"}>
                                        Записаться
                                </Button> : ''
                            const usedTag = <Tag color="green">Пройден</Tag>
                            const infoIcon = <Tooltip title="Информация о программе" className={s.searchStyle}>
                                <InfoCircleOutlined onClick={() => variationInfo(
                                    props.data.product_id,
                                    item.variation_id,
                                    title
                                )} />
                                </Tooltip>

                            //console.log(item)

                            return <li key={index} className={`${disabled ? s.disabled : ''}`}>
                                <div className={s.left}>
                                    <div className={s.checkmark}>
                                        {/* {avatar} */}
                                    </div>
                                    <div className={s.main_info}>
                                        <div className={s.main_info_top}>
                                            <span className={s.title}>{title}</span>
                                            <span className={s.info}>{infoIcon}</span>
                                        </div>
                                        <div className={s.main_info_bottom}>
                                            {!item.disabled ? infoButton : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.right}>
                                    {item.used ? usedTag : ''}
                                </div>
                            </li>
                        })}

                    </ul>

                </div>

                <div className={s.buying_date}>
                    {props.data.expired && <Alert message="Срок действия сертифката истек" type="error" />}
                    <span>Срок действия до: <b>{props.data.expire_date}</b></span>
                </div>

                {(!props.data.expired && !props.data.activated) ?
                    <div className={`${s.buttonWrapper} activation_button_style`}>

                            <Button
                            type="primary"
                            size={"large"}
                            onClick={props.showModal}
                            disabled={disabledActivationButton}
                            className={`button_color ${props.data.category}`}
                        >
                            {!paymentMethod ? 'Не оплачено' : 'Активировать билет'}
                        </Button>

                    </div> : null
                }

                {(props.data.activated && !props.isAuth) && <div className={s.ticket_already_activated}>
                    Билет активирован. Для использования услуги покажите номер билета <b>({ticketNumberWithSpaces})</b> исполнителю.
                </div>}
            </div>
        </div>

        {props.data.qr_code &&
        <div className={s.qr_code}>
            <img src={props.data.qr_code}/>
        </div>
        }

        {(!props.data.expired & props.data.activated) ?
                    <div className={s.activated_button_center}>
                        <Button type="primary" size={"large"} onClick={props.showRedeemModal}
                                className={`button_color ${props.data.category}`}>
                            Погасить услугу
                        </Button>
                    </div> : null
        }
    </>
}

export default ActivateTicket