import React, {useEffect, useState} from 'react'
import {Descriptions, PageHeader, Button, Statistic, Divider, Modal, Collapse, Row, Col, Card, Tag} from 'antd'
import TicketReedemModal from "./TicketReedemModal"
import TicketExecutorCell from "./TicketExecutorCell"
import {NavLink, Redirect, withRouter} from "react-router-dom"
import Link from 'react-router-dom/Link'
import CollapsePanel from "antd/es/collapse/CollapsePanel"
import s from './Ticket.module.css'

const Ticket = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const RedeemModalHandleOk = () => {
        setIsModalVisible(false)
    }

    const RedeemModalHandleCancel = () => {
        setIsModalVisible(false)
    }

    const togglePayment = () => {
        props.togglePayment(props.data.ticket.unique_id).then(() => {
            props.getTicket(props.data.ticket.unique_id)
        })
    }

    return <>

        <TicketReedemModal
            isModalVisible={isModalVisible}
            handleCancel={RedeemModalHandleCancel}
            handleOk={RedeemModalHandleOk}
            activated={props.data.ticket.activated}
            activateTicket={props.activateTicket}
        />

        <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={props.data.ticket.ticket_title}
            extra={[
                props.data.ticket.payment_status === 0 ? <Button key="1" type="primary" onClick={() => togglePayment()} danger>Не оплачен</Button> :
                    <Button key="1" type="primary" onClick={() => togglePayment()}>Оплачено</Button>,
                    <Button key="2"><NavLink to={'/activate/' + props.data.ticket.unique_id}>Активация</NavLink></Button>
            ]}
        />

        <div style={{ padding: "0px 20px 20px 20px" }}>
            <Divider />
            <Descriptions style={{ marginTop: 10 }} title="Информация о билете">
                <Descriptions.Item label="Активирован">{props.data.ticket.activated ? 'Да' : 'Нет'} {props.data.ticket.activated && '(' + props.data.ticket.activation_date + ')'}</Descriptions.Item>
                {props.data.ticket.is_used && <Descriptions.Item label="Дата активации">{props.data.ticket.date_of_used}</Descriptions.Item>}
                <Descriptions.Item label="Идентификатор">{props.data.ticket.id}</Descriptions.Item>
                <Descriptions.Item label="Номер билета">{props.data.ticket.unique_id}</Descriptions.Item>
                <Descriptions.Item label="Мультисертефикат">{props.data.ticket.multiple ? 'Да' : 'Нет'}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions style={{ marginTop: 30 }} title="Информация о покупателе">
                <Descriptions.Item label="Имя">{props.data.ticket.customer_name} {props.data.ticket.customer_surname}</Descriptions.Item>
                <Descriptions.Item label="Email">{props.data.ticket.customer_email}</Descriptions.Item>
                <Descriptions.Item label="Город">{props.data.ticket.customer_city}</Descriptions.Item>
                <Descriptions.Item label="Метод оплаты">{props.data.ticket.payment_method_title}</Descriptions.Item>
                <Descriptions.Item label="Телефон">{props.data.ticket.customer_phone}</Descriptions.Item>
                <Descriptions.Item label="Дата продажи">{props.data.ticket.buying_date}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions style={{ marginTop: 30 }} title="Информация о получателе">
                <Descriptions.Item label="Имя получателя">{props.data.ticket.ticket_owner_fullname}</Descriptions.Item>
                <Descriptions.Item label="Телефон получателя">{props.data.ticket.ticket_owner_phone}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Statistic style={{ marginTop: 30 }} title="Стоимость" value={props.data.ticket.price + ' ' + props.data.ticket.currency} />
            <Statistic style={{ marginTop: 30 }} title={`Рекомендованная стоимость (на дату ${props.data.ticket.buying_date})`} value={props.data.ticket.recommended_price + ' ' + props.data.ticket.currency} />
            <Divider />
            <NavLink to='/executor/{props.data.ticket.executor.id}' key="1"><TicketExecutorCell id={props.data.ticket.executor.id} name={props.data.ticket.executor.name} surname={props.data.ticket.executor.surname}/></NavLink>

            <Divider />


            {/* <h2>Купленные услуги</h2> */}
            {/* <Row gutter={24}>
                <Col span={24}>
                    <Card type="inner" style={{ marginTop: 16 }} title={`${props.data.ticket.variations.title} (#${props.data.ticket.variations.product_id}) / ${props.data.ticket.variations.price} BYN`} bordered={true}>
                        Количество: {props.data.ticket.variations.attributes.value}
                        <br/>
                        Тип программы: {props.data.ticket.variations.attributes.title}
                    </Card>
                </Col>
            </Row> */}

            <Divider />

            <h2>Доступные услуги</h2>
            <div className='activated_count'>
                Доступно услуг для активации: {props.data.ticket.available_count}
            </div>
            <ul className='${}'>
                    {
                        props.data.ticket.product_variations.map((item, key) => {
                            if(!item) return null
                            let style = ''
                            if(props.data.ticket.activation_status && typeof props.data.ticket.activation_status != null) {
                                style = props.data.ticket.activation_status.includes(item.variation_id) ? s.activated : ''
                            }
                            return <li type="inner" style={{ marginTop: 16 }} title={`${item.title} (#${item.product_id})`} bordered={true}>
                                {item.title} {item.activated ? <Tag color="green">Активировано</Tag> : ''}
                            </li>
                        })
                    }
            </ul>

        </div>
    </>
}

export default withRouter(Ticket)