import React from 'react'
import {Alert, Modal, Spin} from "antd"
import s from './RegisterModal.module.scss'

const RegisterModal = props => {
    return <Modal
        title={`Записаться на услугу: ${props.title}`}
        visible={props.visible}
        onCancel={props.hideModal}
        className={s.customStyle}
        footer={null}
    >
        {
            props.variationDescription ? <>
                {/*{props.variationDescription.phone &&*/}
                {/*    <span>Телефон для записи: <b><a href={"tel:"+props.variationDescription.phone} style={{color: '#fff'}}>{props.variationDescription.phone}</a></b></span>*/}
                {/*}*/}

                {props.variationDescription.tutorial ?
                    <Alert
                        message={null}
                        description=<p dangerouslySetInnerHTML={{ __html: props.variationDescription.tutorial}}></p>
                        type="info"
                        showIcon
                        style={{marginTop: '20px'}}
                    />
                : <p dangerouslySetInnerHTML={{ __html: `Инструкция для записи отсутвует.<br>Свяжитесь по номеру телефона <a href="tel:+375296721731">+375(29) 672-17-31</a>`}}></p>
                }
            </>
            :
            <div className={s.spinner}>
                <Spin />
            </div>
        }
    </Modal>
}

export default RegisterModal