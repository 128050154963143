import {ticketsAPI} from "../api/api";
import {setAuthAC} from "./authReducer";

const SET_TICKETS = 'SET_TICKETS';

let initialState = {
    tickets: []
};

const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TICKETS: {
            return{
                ...state,
                tickets: action.tickets
            }
        }
        default:
            return state;
    }
}

export const getTicketsThunk = (page) => {
    return async (dispatch) => {
        let data = await ticketsAPI.getTickets(page);
        if(data.result_code == 1) {
            return setAuthAC(false);
        }
        dispatch(setTicketsAC(data))
    };
};

let setTicketsAC = (tickets) => {
    return {
        type: SET_TICKETS,
        tickets
    }
}
export default ticketsReducer;

