import React, {useEffect, useState} from 'react'
import {NavLink} from "react-router-dom";
import {IdcardOutlined, UnorderedListOutlined, UserOutlined, FileOutlined, AppstoreOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {Menu} from "antd";
import {setSidebarItem} from "../../redux/sidebarReducer";

const MenuSidebar = (props) => {
    const selectedItemState = props.sidebar
    const [selectedItem, setSelectedItem] = useState(selectedItemState)

    useEffect(() => {
        setSelectedItem(selectedItemState)
    }, [selectedItemState])

    const menu = [
        {
            'title': 'Билеты',
            'link': 'tickets',
            'icon': <UnorderedListOutlined />,
            'admin_only': true
        },
        {
            'title': 'Исполнители',
            'link': 'executors',
            'icon': <IdcardOutlined />,
            'admin_only': true
        },
        {
            'title': 'Отчеты',
            'link': 'reports',
            'icon': <FileOutlined />,
            'admin_only': true
        },
        {
            'title': 'Продукты',
            'link': 'products',
            'icon': <AppstoreOutlined />,
            'admin_only': true
        },
        {
            'title': 'Профиль',
            'link': 'profile',
            'icon': <UserOutlined />,
            'admin_only': false
        }
    ]

    const selectSidebarItem = (item) => {
        props.setSidebar(item)
    }

    return <Menu
        mode="inline"
        selectedKeys={[selectedItem]}
        style={{ height: '100%', borderRight: 0 }}
    >
        {
            menu.map(item => {
            if (item.admin_only && props.status === 'admin') {
                return <Menu.Item key={item.link} icon={item.icon}>
                    <NavLink onClick={() => selectSidebarItem(item.link)} to={`/${item.link}`} selected>{item.title}</NavLink>
                </Menu.Item>
            } else if (!item.admin_only) {
                return <Menu.Item key={item.link} icon={item.icon}>
                    <NavLink onClick={() => selectSidebarItem(item.link)} to={`/${item.link}`}
                             selected>{item.title}</NavLink>
                </Menu.Item>
            }
        })
    }
    </Menu>
}

const mapStateToProps = (state) => ({
    sidebar: state.sidebar.sidebarItem,
    status: state.auth.status
})

export default connect(mapStateToProps, {
    setSidebar: setSidebarItem
})(MenuSidebar)
