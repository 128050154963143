import React from 'react';
import { connect } from "react-redux";
import { getExecutorsThunk } from "../../redux/executorsReducer"
import { Spin, Pagination } from 'antd';
import { PageHeader, Button, Descriptions } from 'antd';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import Spinner from "../common/Spinner/Spinner";
import ExecutorsTable from "./Executors";
import {setSidebarItem} from "../../redux/sidebarReducer";
import {adminOnly} from "../../hoc/adminOnly";

class ExecutorsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isDataLoading: true,
            current: 1,
            ticketsParsed: []
        };
    }

    componentDidMount() {
        this.props.setSidebar('executors')
        this.props.getExecutors().then(() => {
            this.setState({isDataLoading: false})
        })
    }

    redirectToAddOrderPage() {
        this.props.history.push('/login');
    }

    render() {
        if(this.state.isDataLoading) {
            return <Spinner />
        }

        return <>
                <PageHeader
                    className="site-page-header"
                    ghost={false}
                    title="Исполнители"
                    extra={[
                        <Button key="1" onClick={e => this.setState({ redirect: true })}>Добавить исполнителя</Button>
                    ]}
                />
                <ExecutorsTable data={this.props.executors} pagination={{pageSize: 1}}/>
            </>
    }
}

let executorsAdminRedirect = adminOnly(ExecutorsContainer)

let mapDispatchToProps = (state) => {
    return {
        executors: state.executors.executors,
    }
}

export default connect(mapDispatchToProps, {
    getExecutors: getExecutorsThunk,
    setSidebar: setSidebarItem
})(executorsAdminRedirect)

