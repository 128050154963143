import {ticketsAPI} from "../api/api"

const SET_REPORT = 'SET_REPORT'
const SET_FAILED_REPORT = 'SET_FAILED_REPORT'
const CLEAR_REPORT = 'CLEAR_REPORT'
const SET_REPORT_STATUS = 'SET_REPORT_STATUS'
const SET_REPORT_STATUS_LOADING = 'SET_REPORT_STATUS_LOADING'
const SET_REPORTS = 'SET_REPORTS'
const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING'
const EDIT_ROW = 'EDIT_ROW'

let initialState = {
    report_link: null,
    error: false,
    error_reason: null,
    report_status_loading: false,
    report_status: [],
    reports: [],
    reports_loading: true
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REPORT: {
            return {
                ...state,
                report_link: action.report.link,
                error: false,
                error_reason: null
            }
        }
        case SET_FAILED_REPORT: {
            return {
                ...state,
                error: true,
                error_reason: action.report.error
            }
        }
        case CLEAR_REPORT: {
            return {
                ...state,
                report_link: null,
                error: false,
                error_reason: null
            }
        }
        case SET_REPORT_STATUS: {
            return {
                ...state,
                report_status: action.reportStatus,
            }
        }
        case SET_REPORT_STATUS_LOADING: {
            return {
                ...state,
                report_status_loading: action.loadingStatus,
            }
        }
        case SET_REPORTS: {
            return {
                ...state,
                reports: action.reports
            }
        }
        case SET_REPORTS_LOADING: {
            return {
                ...state,
                reports_loading: action.status
            }
        }
        case EDIT_ROW: {
            return {
                ...state,
               reports: state.reports.map(item => {
                   if (item.id === action.id) {
                       return {
                           ...item,
                           status: item.status === 'Оплачено' ? 'Не оплачено' : 'Оплачено'
                       }
                   }
                   return item
                })
            }
        }
        default:
            return state
    }
}

export const getReport = () => {
    return async (dispatch) => {
        let data = await ticketsAPI.getReport()
        if(data.result_code === 1) {
            return dispatch(setReportFailedAC(data))
        }
        return dispatch(setReportAC(data));
    }
}

let setReportAC = report => {
    return {
        type: SET_REPORT,
        report
    }
}

let setReportFailedAC = report => {
    return {
        type: SET_FAILED_REPORT,
        report
    }
}

export const clearReport = () => {
    return async (dispatch) => {
        return dispatch(clearReportAC())
    }
}

let clearReportAC = () => {
    return {
        type: CLEAR_REPORT
    }
}

export const getReportStatus = () => {
    return async (dispatch) => {
        dispatch(setReportStatusLoadingAC(true))
        let data = await ticketsAPI.getReportStatus()
        dispatch(setReportStatusAC(data))
        return dispatch(setReportStatusLoadingAC(false))
    }
}

let setReportStatusAC = reportStatus => {
    return {
        type: SET_REPORT_STATUS,
        reportStatus
    }
}

let setReportStatusLoadingAC = status => {
    return {
        type: SET_REPORT_STATUS_LOADING,
        loadingStatus: status
    }
}

export const getAllReports = () => {
    return async (dispatch) => {
        dispatch(setReportsLoadingAC(true))
        let data = await ticketsAPI.getReports()
        dispatch(setReportsLoadingAC(false))
        return dispatch(getAllReportsAC(data))
    }
}

let getAllReportsAC = reports => {
    return {
        type: SET_REPORTS,
        reports
    }
}

let setReportsLoadingAC = status => {
    return {
        type: SET_REPORTS_LOADING,
        status
    }
}

export const reportTogglePayment = (id) => {
    return async (dispatch) => {
        let data = await ticketsAPI.reportTogglePayment(id)
    }
}

export const setUserReportTemplate = (id, title) => {
    return async (dispatch) => {
        let data = await ticketsAPI.setUserTemplate(id, title)
    }
}


export const editReportsData = (id) => {
    return async (dispatch) => {
        dispatch(editReportsDataAC(id))
    }
}

let editReportsDataAC = id => {
    return {
        type: EDIT_ROW,
        id
    }
}

export default reportReducer

