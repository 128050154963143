import React from 'react'
import {connect} from "react-redux"
import {Redirect, withRouter} from "react-router-dom"
import {activateTicketSendCode, activateTicketThunk, getTicketThunk} from "../../redux/ticketReducer";
import {setActivationPage} from "../../redux/appReducer";

class TestContainer extends React.Component {
    hello = false

    render() {
        if(this.hello) {
            return <h1>Test</h1>
        }
        return <Redirect to={'/login'} />
    }
}

export default connect(null, {
    getTicket: getTicketThunk,
    activateTicket: activateTicketThunk,
    activateTicketSendCode: activateTicketSendCode,
    setActivationTicket: setActivationPage
})(TestContainer)