import React from 'react'
import {Drawer, Menu} from 'antd'
import {IdcardOutlined, UnorderedListOutlined, UserOutlined} from '@ant-design/icons'
import {NavLink} from 'react-router-dom'
import s from './SidebarMobile.module.scss'
import MenuSidebar from "../Sidebar/MenuSidebar";

const SidebarMobile = props => {
    return <Drawer
        placement={'left'}
        closable={false}
        visible={props.show}
        key={'left'}
        tapToClose={true}
        className={s.sidebar_mobile}
        onClose={props.toggleSidebar}
        width={'60%'}
        >
            <MenuSidebar />
        </Drawer>
}

export default SidebarMobile