import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { getExecutorThunk } from "../../redux/executorReducer"
import { PageHeader, Button, Descriptions } from 'antd';
import {Redirect, withRouter} from "react-router-dom";
import Spinner from "../common/Spinner/Spinner";
import Executor from "./Executor";
import {adminOnly} from "../../hoc/adminOnly";
import Report from "./Report/Report";

const ExecutorContainer = props => {
    const [showReportModal, setShowReportModal] = useState(false)
    const executorId = props.match.params.id;

    useEffect(() => {
        props.getExecutor(executorId)
    }, [])

    if(props.loading) return <Spinner />

    return <>
        <Report isModalVisible={showReportModal}
                onOk={() => setShowReportModal(true)}
                handleCancel={() => setShowReportModal(false)}
        />
        <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={props.executor.name + ' ' + props.executor.surname}
            extra={
                <Button onClick={() => setShowReportModal(true)}>Отчет</Button>
            }
        />
        <Executor data={props.executor}/>
    </>
}

let mapDispatchToProps = (state) => {
    return {
        executor: state.executor,
        loading: state.executor.loading
    }
}

let withRouterExecutorContainer = withRouter(ExecutorContainer)
let executorAdminRedirect = adminOnly(withRouterExecutorContainer)

export default connect(mapDispatchToProps, {
    getExecutor: getExecutorThunk,
})(executorAdminRedirect)

