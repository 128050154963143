import React from 'react'
import Ticket from "./Ticket"
import {connect} from "react-redux"
import {getTicketThunk, activateTicketSendCode, togglePayment} from "../../redux/ticketReducer"
import {Redirect, withRouter} from "react-router-dom"
import {Spin} from "antd"
import Spinner from "../common/Spinner/Spinner"
import {withAuthRedirect} from "../../hoc/withAuthRedirect";

class TicketContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDataLoading: true,
            ticketId: null
        }
    }

    componentDidMount() {
        let ticketId = this.props.match.params.id
        this.getTicket(ticketId)
    }

    getTicket = (ticketId) => {
        this.props.getTicket(ticketId).then(() => {
            this.setState({
                isDataLoading: false,
                ticketId: ticketId
            })
        })
    }

    activateTicket(code, disabled) {
        let finalCode = code ? code : 1234
        this.props.activateTicket(this.state.ticketId, finalCode)
    }

    render() {
        if(this.state.isDataLoading) {
            return <Spinner />
        }

        return <div>
            <Ticket
                activateTicket={this.activateTicket.bind(this)}
                data={this.props}
                getTicket={this.getTicket}
                togglePayment={this.props.togglePayment}
            />
        </div>
    }
}

let mapDispatchToProps = (state) => {
    return {
        ticket: state.ticket.ticket,
    }
}

const withRouterTicketContainer = withRouter(TicketContainer)
const TicketWithAuthRedirect = withAuthRedirect(withRouterTicketContainer)

export default connect(mapDispatchToProps, {
    getTicket: getTicketThunk,
    activateTicket: activateTicketSendCode,
    togglePayment: togglePayment
})(TicketWithAuthRedirect)
