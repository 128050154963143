import React, {useState} from 'react'
import {connect} from "react-redux"
import {
    activateTicketSendCode,
    activateTicketThunk,
    getTicketThunk, variationInfo, variationInfoClear
} from "../../redux/ticketReducer"
import {Redirect, withRouter} from "react-router-dom"
import {Result, Spin, Button, Typography} from "antd"
import Spinner from "../common/Spinner/Spinner"
import ActivateTicket from "./ActivateTicket"
import ActivateModal from "./ActivateModal/ActivateModal"
import {setActivationPage} from "../../redux/appReducer";
import Paragraph from "antd/es/typography/Paragraph";
import Text from "antd/es/typography/Text";
import { CloseCircleOutlined } from '@ant-design/icons';


class ActivateTicketContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDataLoading: true,
            isShowActivateModal: false,
            isShowRedeemModal: false,
            isShowLoginModal: false,
            ticketId: null
        }
    }

    componentDidMount() {
        let ticketId = this.props.match.params.id
        this.props.getTicket(ticketId).then(() => {
            this.setState({
                isDataLoading: false,
                ticketId: ticketId
            })
        })
        this.props.setActivationTicket(true)
    }

    showActivateModal = () => {
        let ticketId = this.props.match.params.id
        this.props.activateTicket(ticketId)
        this.setState({isShowActivateModal: true})
    }

    hideActivateModal = () => {
        this.setState({isShowActivateModal: false})
    }

    hideLoginModal = () => {
        this.setState({isShowLoginModal: false})
    }

    showRedeemModal = () => {
        if(this.props.isAuth) {
            this.setState({isShowRedeemModal: true})
        } else {
            this.setState({isShowLoginModal: true})
        }
    }

    hideRedeemModal = () => {
        this.setState({isShowRedeemModal: false})
    }

    render() {
        if(this.state.isDataLoading) {
            return <Spinner />
        }

        if(this.props.ticket.result_code === 1) {
            return <div style={{display: 'flex', justifyContent: 'center', height: 'calc(100vh - 100px)', alignItems: 'center'}}><Result
                status="warning"
                title="Билет не найден"
                style={{width: '500px'}}
            >
            </Result></div>
        }

        return <>
            <ActivateTicket data = {this.props.ticket}
                            isModalVisible = {this.state.isShowActivateModal}
                            showModal = {this.showActivateModal}
                            handleCancel = {this.hideActivateModal}
                            activateTicketPhone = {this.props.activatePhone}
                            activateTicketSendCode = {this.props.activateTicketSendCode}
                            // isShowRedeemModal={this.state.isShowRedeemModal}
                            isShowRedeemModal={this.state.isShowRedeemModal}
                            showRedeemModal={this.showRedeemModal}
                            hideRedeemModal={this.hideRedeemModal}
                            showLoginModal = {this.state.isShowLoginModal}
                            hideLoginModal = {this.hideLoginModal}
                            ticketId = {this.state.ticketId}
                            getTicket = {this.props.getTicket}
                            variationInfo = {this.props.variationInfo}
                            variationData = {this.props.variationData}
                            variationInfoClear = {this.props.variationInfoClear}
                            isAuth = {this.props.isAuth}
            />
        </>
    }
}

let mapDispatchToProps = (state) => {
    return {
        ticket: state.ticket.ticket,
        activatePhone: state.ticket.activateTicket,
        isAuth: state.auth.isAuth,
        variationData: state.ticket.variationDescription
    }
}

let withRouterTicketContainer = withRouter(ActivateTicketContainer)

export default connect(mapDispatchToProps, {
    getTicket: getTicketThunk,
    activateTicket: activateTicketThunk,
    activateTicketSendCode: activateTicketSendCode,
    setActivationTicket: setActivationPage,
    variationInfo: variationInfo,
    variationInfoClear: variationInfoClear
})(withRouterTicketContainer)
