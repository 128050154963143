import React, {useState} from 'react'
import Navbar from "../Navbar/Navbar";
import {Layout} from "antd";
import SidebarMobile from "../SidebarMobile/SidebarMobile";
import Sidebar from "../Sidebar/Sidebar";
import {Content} from "antd/es/layout/layout";
import {connect} from "react-redux";

const LayoutActivate = props => {

    const [showMobileSidebar, setShowMobileSidebar] = useState(false)

    const toggleShowMobileSidebar = () => {
        setShowMobileSidebar(!showMobileSidebar)
    }

    return <Layout style={{minHeight: '100vh'}}>
        <Navbar visible={true} openSidebar={toggleShowMobileSidebar}/>
        <Layout>
            <Content className="site-layout-background" style={{padding: 24, margin: 0, minHeight: 280}}>
                {props.children}
            </Content>
        </Layout>
    </Layout>
}

let mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, null)(LayoutActivate)

