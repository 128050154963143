import {ticketsAPI} from "../api/api"

const SET_TICKET = 'SET_TICKET'
const ACTIVATE_TICKET = 'ACTIVATE_TICKET'
const ACTIVATE_AVAILABLE_OPTIONS = 'ACTIVATE_AVAILABLE_OPTIONS'
const ACTIVATE_IS_PHONE_VERIFIED = 'ACTIVATE_IS_PHONE_VERIFIED'
const ACTIVATE_TICKET_LOADING = 'ACTIVATE_TICKET_LOADING'
const IS_TICKET_ACTIVATED = 'IS_TICKET_ACTIVATED'
const VARIATION_INFO = 'VARIATION_INFO'
const VARIATION_INFO_CLEAR = 'VARIATION_INFO_CLEAR'

let initialState = {
    ticket: [],
    activateTicket: [],
    activateAvailableOptions: [],
    activateTicketIsPhoneVerified: null,
    activateTicketLoading: false,
    isTicketActivated: null,
    variationDescription: null
}

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TICKET: {
            return {
                ...state,
                ticket: action.ticket
            }
        }
        case ACTIVATE_TICKET: {
            return {
                ...state,
                activateTicket: action.activate
            }
        }
        case ACTIVATE_AVAILABLE_OPTIONS:
            return {
                ...state,
                activateAvailableOptions: action.options
            }
        case ACTIVATE_IS_PHONE_VERIFIED:
            return {
                ...state,
                activateTicketIsPhoneVerified: action.status
            }
        case ACTIVATE_TICKET_LOADING:
            return {
                ...state,
                activateTicketLoading: action.status
            }
        case IS_TICKET_ACTIVATED:
            return {
                ...state,
                isTicketActivated: action.status
            }
        case VARIATION_INFO:
            return {
                ...state,
                variationDescription: action.info
            }
        case VARIATION_INFO_CLEAR:
            return {
                ...state,
                variationDescription: null
            }
        default:
            return state
    }
}

export const getTicketThunk = (id) => {
    return async (dispatch) => {
        let data = await ticketsAPI.getTicket(id)
        dispatch(setTicketsAC(data))
    }
}

let setTicketsAC = (ticket) => {
    return {
        type: SET_TICKET,
        ticket
    }
}

export const activateTicketThunk = (id) => {
    return async (dispatch) => {
        let data = await ticketsAPI.activateTicket(id)
        dispatch(activateTicketsAC(data))
    }
}

let activateTicketsAC = (activate) => {
    return {
        type: ACTIVATE_TICKET,
        activate
    }
}

const getProgramAvailableOptionsAC = (options) => {
    return {
        type: ACTIVATE_AVAILABLE_OPTIONS,
        options
    }
}

export const getProgramAvailableOptions = (id) => {
    return async (dispatch) => {
        let data = await ticketsAPI.activateTicketGetAvailableOptions(id)

        if(data) {
            dispatch(getProgramAvailableOptionsAC(data))
        }
    }
}

const activateTicketSendCodeAC = (status) => {
    return {
        type: ACTIVATE_IS_PHONE_VERIFIED,
        status: status
    }
}

const activateTicketLoadingAC = (status) => {
    return {
        type: ACTIVATE_TICKET_LOADING,
        status: status
    }
}

export const activateTicketSendCode = (id, digits) => {
    return async (dispatch) => {
        dispatch(activateTicketLoadingAC(true))
        let request = await ticketsAPI.activateTicketSendCode(id, digits)
        let result = request.result_code === 1 ? false : true
        dispatch(activateTicketSendCodeAC(result))
        if(result) {
            dispatch(getProgramAvailableOptions(id))
        }
        dispatch(activateTicketLoadingAC(false))
    }
}

const activateConfirmAC = status => {
    return {
        type: IS_TICKET_ACTIVATED,
        status: status
    }
}

export const activateConfirm = (id, products) => {
    return async (dispatch) => {
        dispatch(activateTicketLoadingAC(true))
        let request = await ticketsAPI.activateConfirm(id, products)
        if(request) {
            let result = request.result_code === 1 ? false : true
            dispatch(activateConfirmAC(result))
        }
        dispatch(activateTicketLoadingAC(false))
    }
}

export const togglePayment = (id) => {
    return async (dispatch) => {
        let request = await ticketsAPI.togglePayment(id)
        if(request) {
            return true
        }
    }
}

const variationInfoAC = info => {
    return {
        type: VARIATION_INFO,
        info: info
    }
}

export const variationInfo = (prodId, id) => {
    return async (dispatch) => {
        let request = await ticketsAPI.getVariationInfo(prodId, id)
        if(request) {
            dispatch(variationInfoAC(request))
        }
    }
}

const variationInfoClearAC = info => {
    return {
        type: VARIATION_INFO_CLEAR
    }
}

export const variationInfoClear = () => {
    return async (dispatch) => {
        dispatch(variationInfoClearAC())
    }
}

export default ticketReducer

