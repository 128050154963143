import {ticketsAPI} from "../api/api";

const SET_EXECUTORS = 'SET_EXECUTORS';

let initialState = {
    executors: []
};

const executorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXECUTORS: {
            return{
                ...state,
                executors: action.executors
            }
        }
        default:
            return state;
    }
}

export const getExecutorThunk = (id) => {
    return async (dispatch) => {
        let data = await ticketsAPI.getExecutor(id);
        dispatch(setExecutorAC(data))
    };
};

let setExecutorAC = (executors) => {
    return {
        type: SET_EXECUTORS,
        executors
    }
}

export const getExecutorsThunk = () => {
    return async (dispatch) => {
        let data = await ticketsAPI.getExecutors();
        dispatch(setExecutorsAC(data))
    };
};

let setExecutorsAC = (executors) => {
    return {
        type: SET_EXECUTORS,
        executors
    }
}

export default executorsReducer;

