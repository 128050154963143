import React, {useEffect, useState} from 'react';
import {Modal, Button, Spin, Form, Input, Alert, Select, Checkbox, Row, Col, Steps, message, List, Avatar} from 'antd';
import s from './ActivateModal.module.scss';
import {ticketsAPI} from "../../../api/api";
import {connect} from "react-redux";
import {
    activateConfirm,
    activateTicketSendCode,
    activateTicketThunk,
    getProgramAvailableOptions,
    getTicketThunk
} from "../../../redux/ticketReducer";
import checkmark_green from "../../../images/checkbox_checkmark.svg";
import close from "../../../images/checkbox_checkmark_empty.svg";

const ActivateModal = (props) => {
    const {Step} = Steps
    const [showSecondStep, setShowSecondStep] = useState(false)
    // const [checked, setChecked] = useState([])
    const selectedCurrentTab = showSecondStep ? 1 : 0
    const [disableActive, setDisableActive] = useState(false)

    // console.log(props);

    const [availableOptionsState, setAvailableOptionsState] = useState({
        isLoaded: false,
        available: [],
        selected: []
        // availableChoose: false,
        // defaultSelected: [],
        // selectCounter: null
    })

    const onFinishFirstStep = data => {
        props.activateTicketSendCode(props.ticketId, data.digits).then(() => {
            console.log(props)
        })
    }

    const onFinishSecondStep = () => {
        if(props.activateOptions.available_status === 1) {
            props.activateConfirm(props.ticketId, availableOptionsState.selected.join(','))
        } else {
            props.activateConfirm(props.ticketId, availableOptionsState.available.join(','))
        }
    }

    const onChange = checkedValues => {
        //console.log(checkedValues)

        //console.log(`1: ${props.activateOptions.available_count} 2: ${checkedValues.length}`)

        setAvailableOptionsState({
            ...availableOptionsState, 
            selected: checkedValues
        })
        

        if(checkedValues.length >= props.activateOptions.available_count) {
            setDisableActive(true)
        } else {
            setDisableActive(false)
        }
    }

    const isDisabled = id => {
        return availableOptionsState.selectCounter.length + 1 > 1 && availableOptionsState.selectCounter.indexOf(id) === -1
    }

    useEffect(() => {
        if(props.activateTicketIsPhoneVerified) {
            setShowSecondStep(true)
        }
    }, [props.activateTicketIsPhoneVerified])

    useEffect(() => {
        props.getTicket(props.ticketId)
        if(props.isTicketActivated === true) {
            props.handleCancel()
            message.success('Услуга активиована')
        }
    }, [props.isTicketActivated])

    useEffect(() => {
        if(props.activateOptions.available_variations) {
            let selectedIds = []

            if(props.activateOptions.available_status !== 1) {
                Object.values(props.activateOptions.available_variations).map(item => {
                    if(item.available_to_select) selectedIds.push(item.variation_id)
                })
            }
            
            setAvailableOptionsState({
                ...availableOptionsState, 
                isLoaded: true,
                available: selectedIds
            })
        }
    }, [props.activateOptions])

    // useEffect(() => {
    //     if(props.activateOptions[0]) {
    //         let availableToSelect = props.activateOptions[0].available_choose
    //         let availableChoose = props.activateOptions[0].available_to_select
    //         let defaultSelected = []

    //         if(!availableToSelect) {
    //             let i = 0
    //             while (i < availableChoose) {
    //                 if(props.activateOptions[0].products[i] !== undefined) {
    //                     defaultSelected.push(props.activateOptions[0].products[i].variation_id)
    //                 }

    //                 i++
    //             }
    //         }

    //         setAvailableOptionsState({
    //             ...availableOptionsState,
    //             isLoaded: true,
    //             availableChoose: availableToSelect,
    //             defaultSelected: defaultSelected,
    //             selectCounter: availableChoose
    //         })
    //     }
    // }, [props.activateOptions])

    return <>
        <Modal title={null}
               visible={props.isModalVisible}
               onOk={props.handleCancel}
               onCancel={props.handleCancel}
               cancelText="Отмена"
               footer={null}
        >
            <div className={s.activate_wrapper}>

                <div className={s.steps}>
                    <Steps size="small" current={selectedCurrentTab}>
                        <Step title="Шаг 1" />
                        <Step title="Шаг 2" />
                    </Steps>
                </div>

                <Alert message="Внимание! В целях безопасности активируйте сертификат перед записью на услугу" type="info" style={{marginTop: '20px'}}/>

                {props.activateTicketLoading && <div className={s.spinner}>
                    <Spin />
                </div>}

                {!showSecondStep ?
                    <div className={s.step_one}>

                        <div className={s.step_header}>
                            <h2>Подтвердите телефон</h2>
                        </div>

                        {props.activatePhone.result_code === 1 &&
                            <h2>Все программы билета погашены</h2>
                        }

                        <Form onFinish={onFinishFirstStep}>
                            <Form.Item className={s.phone_wrapper_center}>
                                <span>{props.activatePhone.data ? props.activatePhone.data.phone_1 : <Spin/>}</span>
                                <Form.Item name="digits">
                                    <Input placeholder={"***"} maxLength="3"/>
                                </Form.Item>

                                <span>{props.activatePhone.data ? props.activatePhone.data.phone_2 : <Spin/>}</span>
                            </Form.Item>

                            {props.activateTicketIsPhoneVerified === false && <div className={s.alert}>
                                <Alert message="Неверный номер" type="error" />
                            </div>}

                            <Form.Item className={s.buttons}>
                                <Button type="primary" htmlType="submit">
                                    Далее
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                :
                availableOptionsState.isLoaded && props.activateOptions.available_variations && <div className={s.step_two}>
                    <Form onFinish={onFinishSecondStep}>
                        <Checkbox.Group
                            disabled={props.activateOptions.available_status !== 1 ? true : false}
                            onChange={onChange}
                            defaultValue={availableOptionsState.available}
                        >
                            {
                                Object.values(props.activateOptions.available_variations).map((item, index) => {
                                    return <Col key={index}>
                                        <Checkbox
                                            key={item.variation_id}
                                            value={item.variation_id}
                                            disabled={props.activateOptions.available_status === 1 &&
                                                      disableActive &&
                                                      !availableOptionsState.selected.includes(item.variation_id) ? 
                                                      true : false
                                                }
                                        >   
                                            {`${index +1}. ${item.title}`}
                                        </Checkbox>
                                    </Col>
                                })
                            }    
                        </Checkbox.Group>

                        <Form.Item className={s.buttons}>
                            <Button type="primary" htmlType="submit">
                                Активировать
                            </Button>
                        </Form.Item>
                    </Form>
                    </div>
                }
            </div>
        </Modal>
    </>
}

let mapDispatchToProps = (state) => {
    return {
        activateOptions: state.ticket.activateAvailableOptions,
        activateTicketIsPhoneVerified: state.ticket.activateTicketIsPhoneVerified,
        activateTicketLoading: state.ticket.activateTicketLoading,
        isTicketActivated: state.ticket.isTicketActivated
    };
};

export default connect(mapDispatchToProps, {
    getProgramAvailableOptions: getProgramAvailableOptions,
    activateTicketSendCode: activateTicketSendCode,
    activateConfirm: activateConfirm
})(ActivateModal);
