import React, {useEffect} from 'react'
import {adminOnly} from "../../hoc/adminOnly";
import {connect} from "react-redux";
import {setSidebarItem} from "../../redux/sidebarReducer";
import {getProducts} from "../../redux/productsReducer";
import Spinner from "../common/Spinner/Spinner";
import {Button, PageHeader, Table, Tag} from "antd";
import {Link} from "react-router-dom";

const ProductsContainer = props => {
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <Link to={'profile/' + record.id}>{text}</Link>
        },
        {
            title: 'Id продукта',
            dataIndex: 'product_id',
            key: 'product_id',
        },
        {
            title: 'Id вариации',
            dataIndex: 'variation_id',
            key: 'variation_id',
        },
        {
            title: 'Имя',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => text ? <span>{text} BYN</span> : <Tag color={'red'}>Цена не установлена</Tag>
        },
        {
            title: 'Рекомендованная цена',
            dataIndex: 'recommended_price',
            key: 'recommended_price',
            render: (text, record) => text ? <span>{text} BYN</span> : <Tag color={'red'}>Цена не установлена</Tag>
        },
        {
            title: 'Артикул',
            dataIndex: 'sku',
            key: 'sku',
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
        },
    ]

    useEffect(() => {
        props.setSidebar('products')
        props.getProducts()
    }, [])

    if(!props.products) return <Spinner />

    return <>
        <PageHeader
            className="site-page-header"
            ghost={false}
            title="Услуги"
        />
        <Table
            dataSource={props.products}
            columns={columns}
            pagination={{ defaultPageSize: 500}}
        />
    </>
}

let productsContainerAdminRedirect = adminOnly(ProductsContainer)

let mapDispatchToProps = (state) => {
    return {
        products: state.products.products,
    }
}

export default connect(mapDispatchToProps, {
    getProducts: getProducts,
    setSidebar: setSidebarItem
})(productsContainerAdminRedirect)