import React, {useState} from 'react'
import {Button, InputNumber, Modal} from "antd";
import {connect} from "react-redux";
import {clearReport, getReport, getReportStatus} from "../../../redux/reportReducer";
import {setUserFee} from "../../../redux/profileReducer";

const SetFeeModal = props => {
    const [userFee, setUserFee] = useState(0)

    const closeModal = () => {
        props.handleCancel()
    }

    const changeFee = fee => {
        setUserFee(fee)
    }

    const confirmFee = fee => {
        props.setUserFee(props.userId, userFee)
        props.updateProfile(props.userId)
        props.handleCancel()
    }

    return <Modal title="Установить комиссию"
                  visible={props.isModalVisible}
                  onOk={props.handleOk}
                  onCancel={closeModal}
                  className={`report_style`}
                  destroyOnClose={true}
                  footer={null}
    >
        <InputNumber
            min={0}
            max={100} 
            defaultValue={props.fee}
            onChange={changeFee}
        />

        <Button type="primary"
                onClick={confirmFee}
                style={{width: '100%', marginTop: '10px'}}
        >Изменить комиссию</Button>
    </Modal>
}

export default connect(null, {
    setUserFee: setUserFee
})(SetFeeModal)