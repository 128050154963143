import React from 'react';
import { connect } from "react-redux";
import { getTicketsThunk } from "../../redux/ticketsReducer"
import TicketTable from "./Tickets";
import { Spin, Pagination } from 'antd';
import { PageHeader, Button, Descriptions } from 'antd';
import {BrowserRouter as Router, Route, withRouter} from "react-router-dom";
import { Redirect } from 'react-router-dom';
import Spinner from "../common/Spinner/Spinner";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";
import {compose} from "redux";
import NotFound from "../NotFound/NotFound";
import {setSidebarItem} from "../../redux/sidebarReducer";
import {adminOnly} from "../../hoc/adminOnly";

class TicketsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isDataLoading: true,
            current: 1,
            ticketsParsed: []
        };
    }

    componentDidMount() {
        this.props.setSidebar('tickets')
        this.props.getTickets().then(() => {
            this.setState({isDataLoading: false})
        })
    }

    redirectToAddOrderPage() {
        this.props.history.push('/login');
    }

    getTicketsWithPage(page) {
        this.props.getTickets(page)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/tickets/new' />
        }

        if(this.state.isDataLoading) {
            return <Spinner />
        }

        return <>
                <PageHeader
                    className="site-page-header"
                    ghost={false}
                    title="Билеты"
                    extra={[
                        <Button key="1" onClick={e => this.setState({ redirect: true })}>Добавить заказ</Button>
                    ]}
                />
                    <TicketTable
                        data={this.props.tickets.data}
                        paginationCurrentPage={this.props.tickets.pagination.current_page}
                        paginationTotal={this.props.tickets.pagination.total}
                        getTickets={this.props.getTickets}
                    />
            </>
    }
}

let mapStateToProps = (state) => {
    return {
        tickets: state.tickets.tickets,
        status: state.auth.status
    }
}

let adminRedirect = adminOnly(TicketsContainer)

export default connect(mapStateToProps, {
        getTickets: getTicketsThunk,
        setSidebar: setSidebarItem
    }
)(adminRedirect)

