import React, {useState} from 'react';
import moment from 'moment';
import { Form , Radio, Modal, DatePicker } from "antd";
const { RangePicker } = DatePicker;

const RedeemTicketModal = (props) => {

    const [activated, setActivated] = React.useState('non-activated');

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    const disabledDateTime = () => {
        return {
            disabledHours: () => this.range(0, 24).splice(4, 20),
            disabledMinutes: () => this.range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }

    const changeActivatedStatus = (selectedActivatedStatus) => {
        setActivated(selectedActivatedStatus.target.value)
    }

    const activateTicket = () => {
        let disabled = activated === 'non-activated' && 1;
    }

    const isActivated = props.activated ? 'activated' : 'non-activated'

    return <>
         <Modal
            title="Погасить билет"
            visible={props.isModalVisible}
            cancelText={'Отмена'}
            onOk={activateTicket}
            onCancel={props.handleCancel}
         >
            <Form>
                <Form.Item label="Статус активации">
                    <Radio.Group defaultValue={isActivated} buttonStyle="solid" onChange={changeActivatedStatus}>
                        <Radio.Button value="non-activated">Не активирован</Radio.Button>
                        <Radio.Button value="activated">Актвирован</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Дата активации" name="layout">
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default RedeemTicketModal;