import {getAuthUserData} from "./authReducer"
import {ticketsAPI} from "../api/api";

const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS'
const ACTIVATION_PAGE = 'ACTIVATION_PAGE'

let initialState = {
    initialized: false,
    globalError: null,
    activationPage: false
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZED_SUCCESS:
            return {
                ...state,
                initialized: true
            }
        case ACTIVATION_PAGE:
            return {
                ...state,
                activationPage: action.status
            }
        default:
            return state
    }
}

export const initializedSuccess = () => ({type: INITIALIZED_SUCCESS})

export const initializeApp = () => (dispatch) => {
    let promise = dispatch(getAuthUserData())

    Promise.all([promise])
        .then(() => {
            dispatch(initializedSuccess())
        })
}

const setActivationPageAC = status => {
    return {
        type: ACTIVATION_PAGE,
        status
    }
}

export const setActivationPage = status => {
    return async (dispatch) => {
        dispatch(setActivationPageAC(status))
    }
}

export default appReducer

