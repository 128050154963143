import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import appReducer from "./appReducer";
import ticketsReducer from "./ticketsReducer";
import { reducer as formReducer } from 'redux-form'
import ticketReducer from "./ticketReducer";
import executorsReducer from "./executorsReducer";
import authReducer from "./authReducer";
import executorReducer from "./executorReducer";
import profileReducer from "./profileReducer";
import activateReducer from "./activateReducer";
import sidebarReducer from "./sidebarReducer";
import reportReducer from "./reportReducer";
import productsReducer from "./productsReducer";

let reducers = combineReducers({
    activate: activateReducer,
    app: appReducer,
    auth: authReducer,
    tickets: ticketsReducer,
    ticket: ticketReducer,
    executors: executorsReducer,
    executor: executorReducer,
    profile: profileReducer,
    report: reportReducer,
    products: productsReducer,
    sidebar: sidebarReducer,
    form: formReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(
    applyMiddleware(thunkMiddleware)
));

window.__store__ = store;

export default store;