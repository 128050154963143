import React, {useEffect, useState} from 'react';
import s from './Sidebar.module.css';
import {Layout, Menu} from "antd";
import MenuSidebar from "./MenuSidebar";
const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = (props) => {
    if(!props.visible ) {
        return;
    }

    return <Sider width={200} className={s.sidebar}>
        <MenuSidebar />
    </Sider>
}

export default Sidebar

