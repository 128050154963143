import React, {useEffect, useState} from 'react'
import {Modal, DatePicker, Button, Alert, Spin} from "antd"
import './Report.scss'
import {connect} from "react-redux";
import {clearReport, getReport, getReportStatus} from "../../../redux/reportReducer";
import Spinner from "../../common/Spinner/Spinner";

const Report = (props) => {

    useEffect(() => {
        props.reportStatus()
    }, [props.report_link])

    useEffect(() => {
        if (!props.report_link && props.report_status.last_report) {
            props.updateProfile()
        }
    }, [props.report_status.last_report])

    const closeModal = () => {
        props.handleCancel()
        props.clearReport()
    }

    const getReport = () => {
        props.getReport()
    }

    const lastReport = (!props.report_link) ?
        props.report_status.last_report ?
            `Последний отчет: ${props.report_status.last_report}` : 'Отчет не делался'
        :
            'Готово, отчет будет доступен во вкладке "Отчеты" в вашем профиле'
    const nextReport = (!props.report_link && !props.report_status.allowed) ?
        <Alert message={`Следующий отчет через ${props.report_status.days_to_next_report} дней (${props.report_status.next_report})`} style={{marginTop: '10px'}} type="warning"/>
        : null

    const generateButton = props.report_link ?
        <a target="_blank" href={props.report_link}>
            <Button type="primary"
                    onClick={getReport}
                    style={{width: '100%', marginTop: '10px', background: '#27ae60', border: '1px solid #27ae60'}}
            >Открыть отчет</Button></a>
        : <Button type="primary"
                  onClick={getReport}
                  style={{width: '100%', marginTop: '10px'}}
                  disabled={!props.report_status.allowed}
        >Сгенерировать отчет</Button>

    return <Modal title="Генерация отчета"
                  visible={props.isModalVisible}
                  onOk={props.handleOk}
                  onCancel={closeModal}
                  clearReport={props.clearReport}
                  className={`report_style`}
                  destroyOnClose={true}
                  footer={null}
    >

        <div>{lastReport}</div>
        <div>{nextReport}</div>

        {generateButton}
    </Modal>
}

const mapDispatchToProps = (state) => {
    return {
        report_link: state.report.report_link,
        report_error: state.report.error,
        report_error_reason: state.report.error_reason,
        report_status: state.report.report_status,
        report_status_loading: state.report.report_status_loading
    }
}

export default connect(mapDispatchToProps, {
    getReport: getReport,
    clearReport: clearReport,
    reportStatus: getReportStatus
})(Report)