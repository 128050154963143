import React, {useState} from 'react';
import {Table, Tag, Space, Pagination} from 'antd';
import {Link} from "react-router-dom";

const columns = [
    {
        title: 'id',
        dataIndex: 'unique_id',
        key: 'unique_id',
        render: (text, record) => <Link to={'ticket/' + record.unique_id}>{text}</Link>
    },
    {
        title: 'Заголовок',
        dataIndex: 'ticket_title',
        key: 'ticket_title',
        minWidth: 300,
        render: (text, record) => <Link to={'ticket/' + record.unique_id}>{text}</Link>
    },
    {
        title: 'Город',
        dataIndex: 'customer_city',
        key: 'customer_city',
    },
    {
        title: 'Адресс',
        dataIndex: 'customer_address',
        key: 'customer_address',
    },
    {
        title: 'Имя покупателя',
        dataIndex: 'customer_name',
        key: 'customer_name',
    },
    {
        title: 'Телефон покупателя',
        dataIndex: 'customer_phone',
        key: 'customer_phone',
    },
    {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        render: (text, record) => <span>{text} BYN</span>
    },
    {
        title: 'Дата покупки',
        dataIndex: 'buying_date',
        key: 'buying_date',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => getStatusTag(record.status)
    }
    // {
    //     title: 'В отчете',
    //     dataIndex: 'in_report',
    //     key: 'in_report',
    // },

    // {
    //     title: 'Дата выплаты',
    //     dataIndex: 'paid_date',
    //     key: 'paid_date',
    // },
];

const getStatusTag = status => {
    switch(status.step) {
        case 7:
            return <Tag color={"volcano"}>{status.title}</Tag>
        case 6:
            return <Tag color={"green"}>{status.title}</Tag>
        case 5:
            return <Tag color={"orange"}>{status.title}</Tag>
        case 4:
            return <Tag color={"blue"}>{status.title}</Tag>
        case 3:
            return <Tag color={"cyan"}>{status.title}</Tag>
        case 2:
            return <Tag color={"purple"}>{status.title}</Tag>
        case 1:
            return <Tag color={"red"}>{status.title}</Tag>
    }
}


const TicketTable = props => {
    return <div style={{overflow: 'scroll'}}>
        <Table
            dataSource={props.data}
            columns={columns}
            rowKey="id"
            pagination={{
                defaultPageSize: 20,
                defaultCurrent: 1,
                current: props.paginationCurrentPage,
                total: props.paginationTotal,
                showSizeChanger: false,
                onChange: pageNum => {
                    props.getTickets(pageNum)
                },
            }}
        />
    </div>;
}


export default TicketTable;