// import React from "react";
// import {Redirect, withRouter} from "react-router-dom";
// import {connect} from "react-redux";
// import NotFound from "../components/NotFound/NotFound";
// import {getTicketsThunk} from "../redux/ticketsReducer";
// import {setSidebarItem} from "../redux/sidebarReducer";
//
// const adminOnly = (Component) => {
//     return <Component {...this.props}/>
//     // class RedirectComponent extends React.Component {
//     //     render() {
//     //         //if (this.props.isAdmin !== 'admin') return <NotFound />
//     //
//     //     }
//     // }
//
//     //let ConnectedAdminOnlyComponent = connect(mapStateToPropsForRedirect)(adminOnly)
//     //return ConnectedAdminOnlyComponent
// }
//
// const mapStateToPropsForRedirect = (state) => ({
//     isAdmin: state.auth.status
// });
//
// export default withRouter(connect(mapStateToPropsForRedirect, null
// )(adminOnly))


import React from 'react';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
let mapStateToPropsForRedirect = (state) => ({
    isAdmin: state.auth.status
});
export const adminOnly = (Component) => {
    class RedirectComponent extends React.Component {
        render() {
            if (this.props.isAdmin !== 'admin') return <Redirect to='/login' />
            return <Component {...this.props} />
        }
    }

    let ConnectedAuthRedirectComponent = connect(mapStateToPropsForRedirect) (RedirectComponent);
    return ConnectedAuthRedirectComponent;
}