import React, { useState } from 'react';
import { PageHeader, Form, Input, InputNumber, Button, Radio, Select } from 'antd';
import {Field, reduxForm} from "redux-form";
const { TextArea } = Input;
const { Option } = Select;


const AddNewTicketForm = () => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={onRequiredTypeChange}
        >
            <Form.Item label="Название услуги" required tooltip="Это поле обязательно для заполнения">
                <Field name={"title"} component={() => <Input placeholder="Например, CESSNA 172 (1 час)" />}></Field>
            </Form.Item>

            <Form.Item label="Стоимость" required tooltip="Это поле обязательно для заполнения">
                <Field placeholder={"Login"} name={"login"} component={"input"}/>
                {/*<Field name={"price"} component={() => <InputNumber min={1} max={1000000} defaultValue={3} />}></Field>*/}
            </Form.Item>

            {/*<Form.Item label="Имя покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, Иван" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Фамилия покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, Иванов" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Отчество покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, Иванович" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Телефон покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input.Group compact>*/}
            {/*        <Select defaultValue="+375(29)">*/}
            {/*            <Option value="+37517">+375(17)</Option>*/}
            {/*            <Option value="+37525">+375(25)</Option>*/}
            {/*            <Option value="+37529">+375(29)</Option>*/}
            {/*            <Option value="+37533">+375(33)</Option>*/}
            {/*            <Option value="+37544">+375(44)</Option>*/}
            {/*        </Select>*/}
            {/*        <Input style={{ width: '50%' }} defaultValue="1234567" maxLength={7} />*/}
            {/*    </Input.Group>*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Email покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, example@mail.ru" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Город покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, Минск" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Адрес покупателя" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, проспект Победителей 1" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="ФИО получателя билета" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input placeholder="Например, Иванов Иван Иванич" />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Телефон получателя билета" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <Input.Group compact>*/}
            {/*        <Select defaultValue="+375(29)">*/}
            {/*            <Option value="+37517">+375(17)</Option>*/}
            {/*            <Option value="+37525">+375(25)</Option>*/}
            {/*            <Option value="+37529">+375(29)</Option>*/}
            {/*            <Option value="+37533">+375(33)</Option>*/}
            {/*            <Option value="+37544">+375(44)</Option>*/}
            {/*        </Select>*/}
            {/*        <Input style={{ width: '50%' }} defaultValue="1234567" maxLength={7} />*/}
            {/*    </Input.Group>*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="Комментарий" required tooltip="Это поле обязательно для заполнения">*/}
            {/*    <TextArea rows={4} />*/}
            {/*</Form.Item>*/}


            <Form.Item>
                <Button type="primary">Добавить</Button>
            </Form.Item>
        </Form>
    )
}

const AddNewTicketReduxForm = reduxForm({
    form: 'add_ticket'
})(AddNewTicketForm)


const TicketsNew = () => {
    return (
        <div>
            <PageHeader
                className="site-page-header"
                ghost={false}
                onBack={() => window.history.back()}
                title="Добавить билет"
                extra={[
                    <Button key="1" type="primary">Добавить</Button>
                ]}
            />
            <AddNewTicketReduxForm />
        </div>
    );
};

export default TicketsNew;