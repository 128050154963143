import {authAPI} from "../api/api"
import {stopSubmit} from "redux-form"

const SET_USER_DATA = 'SET_USER_DATA'
const SET_USER_TOKEN = 'SET_USER_TOKEN'
const SET_AUTH = 'SET_AUTH'
const CLEAN_AUTH_DATA = 'CLEAN_AUTH_DATA'

let initialState = {
    userId: null,
    email: null,
    name: null,
    surname: null,
    patronymic: null,
    status: null,
    isAuth: false,
    token: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload
            }
        case SET_USER_TOKEN:
            return {
                ...state,
                ...action.token,
                isAuth: true
            }
        case SET_AUTH:
            return {
                ...state,
                isAuth: action.auth
            }
        case CLEAN_AUTH_DATA:
            return {
                ...state,
                userId: null,
                email: null,
                name: null,
                surname: null,
                patronymic: null,
                status: null,
                isAuth: false,
                token: false
            }
        default:
            return state
    }
}

export const setAuthUserData = (userId, email, name, surname, patronymic, status, status_ru, isAuth) => ({
    type: SET_USER_DATA, payload:
        {userId, email, name, surname, patronymic, status, status_ru, isAuth}
})

export const setUserToken = (token) => ({
    type: SET_USER_TOKEN,
    token: {token}
})

export const getAuthUserData = () => async (dispatch) => {
    let response = await authAPI.me()

    if (response.data.resultCode === 0) {
        let {id, email, name, surname, patronymic, status, status_ru} = response.data
        dispatch(setAuthUserData(id, email, name, surname, patronymic, status, status_ru, true))
    }
}

export const loginAC = (email, password) => async (dispatch) => {
    let response = await authAPI.login(email, password)
    if (response.data.result_code === 0) {
        dispatch(setUserToken(response.data.token))
        dispatch(getAuthUserData())
    } else {
        let message = "Some error"
        dispatch(stopSubmit("login", {_error: message}))
    }
}

export const logout = () => async (dispatch) => {
    let response = await authAPI.logout()
    if (response) {
        dispatch(setAuthUserData(null, null, null, null, null, null, null, false))
    }

}

export const setAuthAC = (auth = false) => async (dispatch) => {
    dispatch(setAuth(auth))
}

const setAuth = (auth) => ({
    type: SET_AUTH,
    auth: {auth}
})

export const getToken = () => {
    return sessionStorage.getItem('cs_access_token')
}

export const cleanAuthDataAC = () => ({
    type: CLEAN_AUTH_DATA
})

export const cleanAuthData = () => {
    return async (dispatch) => {
        dispatch(cleanAuthDataAC())
    }
}

export default authReducer