import React from 'react'
import {Layout} from "antd"

const LayoutEmpty = props => (
    <Layout style={{ minHeight: '100vh' }}>
        <Layout>
            {props.children}
        </Layout>
    </Layout>
)

export default LayoutEmpty

