import React from 'react';
import {connect} from "react-redux";
import {getAuthUserData, loginAC} from "../../redux/authReducer";
import {Redirect} from "react-router-dom";
import {Button, Checkbox, Form, Input} from "antd";
import s from "./../common/FormsControls/FormsControls.module.css"
import Login from "./Login";
import {required} from "../../utils/validators/validators";
import {createField, CustomInput} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import s1 from './Login.module.scss'

const LoginForm = ({handleSubmit, error, forModal}) => {
    let forModalStyle = forModal ? s1.forModal : '';

    return <div className={`${s1.loginWindow} ${forModalStyle}`}>
            {!forModal ? <h2>Авторизация</h2> : <h3>Для продолжения необходимо авторизоваться</h3>}

            <form onSubmit={handleSubmit}>
                {createField("Email", "email", [required], CustomInput)}
                {createField("Password", "password", [required], CustomInput, {type: "password"})}

                {error && <div className={s1.error}>
                    Неверный логин или пароль
                </div>
                }
                <div>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Войти
                    </Button>
                </div>
            </form>
        </div>
}

const LoginReduxForm = reduxForm({form: 'login'})(LoginForm)

const LoginContainer = (props) => {
    const onSubmit = (formData) => {
        props.loginAC(formData.email, formData.password);
    }

    if (props.isAuth && !props.forModal) {
        return <Redirect to={"/profile"}/>
    }

    if (props.isAuth && props.forModal) {
        props.closeModal()
    }

    if(props.forModal) {
        return <div>
            <LoginReduxForm onSubmit={onSubmit} forModal={true}/>
        </div>
    }

    return <div className={s1.loginBackground}>
        <LoginReduxForm onSubmit={onSubmit} forModal={false}/>
    </div>
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth
})

export default connect(mapStateToProps, {loginAC})(LoginContainer);