import React, {useState} from 'react';
import {Table, Tag, Space, Pagination} from 'antd';
import {Link} from "react-router-dom";

const columns = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => <Link to={'profile/' + record.id}>{text}</Link>
    },
    {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <Link to={'profile/' + record.id}>{text + ' ' + record.surname}</Link>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Шаблон отчета',
        dataIndex: 'report_template',
        key: 'report_template',
        render: (text, record) => record.report_tamplate ? <span>{record.report_tamplate}</span> : <Tag color={"red"}>Шаблон не установлен</Tag>
    },
    {
        title: 'Комиссия',
        dataIndex: 'fee',
        key: 'fee',
        render: (text, record) => record.fee ? <span>{record.fee}%</span> : <Tag color={"red"}>Комиссия не установлена</Tag>
    }
]

const ExecutorsTable = props => {
    return <Table dataSource={props.data} columns={columns}/>
}

export default ExecutorsTable