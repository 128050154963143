import {connect, Provider} from "react-redux"
import {compose} from "redux"
import store from "./redux/redux-store"
import {BrowserRouter, Route, withRouter} from "react-router-dom"
import 'antd/dist/antd.css'
import {Layout, Space, Spin} from 'antd'
import {initializeApp} from "./redux/appReducer"
import React, {Component} from "react"
import TicketsContainer from "./components/Tickets/TicketsContainer"
import TicketsNew from "./components/Tickets/TicketsNew"
import TicketContainer from "./components/Ticket/TicketContainer"
import ExecutorsContainer from "./components/Executors/ExecutorsContainer"
import ActivateTicketContainer from "./components/ActivateTicket/ActivateTicketContainer"
import ExecutorContainer from "./components/Executor/ExecutorContainer"
import LoginContainer from "./components/Login/LoginContainer"
import ProfileContainer from "./components/Profile/ProfileContainer"
import TestContainer from "./components/TestContainer/TestContainer";
import LayoutMain from "./components/Layout/LayoutMain";
import LayoutActivate from "./components/Layout/LayoutActivate";
import LayoutEmpty from "./components/Layout/LayoutEmpty";
//import './App.css'
import './App.less'
import {Helmet} from "react-helmet";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import ReportsContainer from "./components/Reports/ReportsContainer";
import ProductsContainer from "./components/Products/ProductsContainer";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMobileSidebar: false
        }
    }

    componentDidMount() {
        //document.title = "DreamBox.by - система активации"
        this.props.initializeApp()
    }

    render() {
        if (!this.props.initialized) {
            return <>
                <Helmet>
                    <title>DreamBox.by - система активации</title>
                    <meta name="title" content="DreamBox.by - активация" data-react-helmet="true" />
                    <meta name="description" content="Система активации сертификатов" />
                    <meta name="color-scheme" content="light only" />
                </Helmet>
                <div style={{width: '100%', background: '#141414', height: '100%', position: 'absolute', top: 0, display: 'flex', justifyContent: 'center'}}>
                    <Space size="middle">
                        <Spin size="large" />
                    </Space>
                </div>
            </>

        }

        const renderWithLayout = (Component, Layout) => {
            return <Layout><Component /></Layout>
        }

        return <>
            <Route path="/tickets" render={() => renderWithLayout(TicketsContainer, LayoutMain)}/>
            <Route path="/ticket/:id" exact render={() => renderWithLayout(TicketContainer, LayoutMain)}/>

            <Route path="/login" exact render={() => renderWithLayout(LoginContainer, LayoutEmpty)}/>
            <Route path="/tickets/new" exact render={() => renderWithLayout(TicketsNew, LayoutMain)}/>
            <Route path="/executors" exact  render={() => renderWithLayout(ExecutorsContainer, LayoutMain)}/>
            <Route path="/executor/:id" exact  render={() => renderWithLayout(ExecutorContainer, LayoutMain)}/>

            <Route path="/products" exact  render={() => renderWithLayout(ProductsContainer, LayoutMain)}/>

            <Route path="/reports" exact  render={() => renderWithLayout(ReportsContainer, LayoutMain)}/>

            <Route path="/" exact render={() => renderWithLayout(ProfileContainer, LayoutMain)}/>
            <Route path="/profile" exact render={() => renderWithLayout(ProfileContainer, LayoutMain)}/>
            <Route path="/profile/:id" exact render={() => renderWithLayout(ProfileContainer, LayoutMain)}/>
            <Route path="/activate/:id" exact  render={() => renderWithLayout(ActivateTicketContainer, LayoutActivate)}/>
            <Route path="/test" exact  render={() => renderWithLayout(TestContainer, LayoutMain)}/>
        </>
    }
}

let mapStateToProps = (state) => ({
    initialized: state.app.initialized,
    isAuth: state.auth.isAuth,
    auth: state.auth,
    isActivationPage: state.app.activationPage
})

let AppContainer = compose(
    withRouter,
    connect(mapStateToProps, {initializeApp}))(App)

const ControlSystemApp = (props) => {
    return <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <AppContainer />
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
}

export default ControlSystemApp
    