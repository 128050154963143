const SET_SIDEBAR = 'SET_SIDEBAR'

let initialState = {
    sidebarItem: 'profile'
}

const executorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR: {
            return {
                ...state,
                sidebarItem: action.item
            }
        }
        default:
            return state
    }
}

const setSidebarAC = item => {

    return {
        type: SET_SIDEBAR,
        item
    }
}

export const setSidebarItem = item => {
    return async (dispatch) => {
        dispatch(setSidebarAC(item))
    }
}


export default executorReducer

