import React, {useEffect} from 'react'
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {activateTicketSendCode, getTicketThunk} from "../../redux/ticketReducer"
import Profile from "./Profile"
import {getMyProfile, getProfile} from "../../redux/profileReducer"
import {adminOnly} from "../../hoc/adminOnly";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";
import {getReport} from "../../redux/reportReducer";
import Spinner from "../common/Spinner/Spinner";

const ProfileContainer = props => {
    if (!props.userId) return <Spinner />

    const userId = props.match.params.id ? props.match.params.id : props.userId

    useEffect(() => {
        props.getProfile(userId)
    }, [])

    return <Profile
        data={props.user}
        status={props.status}
        userId={userId}
        updateProfile={props.getProfile}
    />
}

const mapDispatchToProps = (state) => {
    return {
        userId: state.auth.userId,
        user: state.profile.profile,
        status: state.auth.status
    }
}

let authRedirect = withAuthRedirect(ProfileContainer)
const withRouterProfileContainer = withRouter(authRedirect)

export default connect(mapDispatchToProps, {
    getProfile: getMyProfile,
    getReport: getReport
})(withRouterProfileContainer)