import {ticketsAPI} from "../api/api"

const REDEEM_TICKET = 'REDEEM_TICKET'
const REDEEM_TICKET_SEND_CODE = 'REDEEM_TICKET_SEND_CODE'
const REDEEM_TICKET_SAVED = 'REDEEM_TICKET_SAVED'
const REDEEM_STEP_TWO = 'REDEEM_STEP_TWO'
const REDEEM_STEP_THREE =  'REDEEM_STEP_THREE'

let initialState = {
    redeemTicket: [],
    redeemTicketWithCode: [],
    redeemTicketSaved: null,
    redeemStepTwo: []
}

const activateReducer = (state = initialState, action) => {
    switch (action.type) {
        case REDEEM_TICKET:
            return {
                ...state,
                redeemTicket: action.data
            }
        case REDEEM_TICKET_SEND_CODE:
            return {
                ...state,
                redeemTicketWithCode: action.data
            }
        case REDEEM_TICKET_SAVED:
            return {
                ...state,
                redeemTicketSaved: action.data
            }
        case REDEEM_STEP_TWO:
            return {
                ...state,
                redeemStepTwo: action.data
            }
        case REDEEM_STEP_THREE:
            return {
                ...state,
                redeemStepThree: action.data
            }
        default:
            return state
    }
}

const redeemTicketAC = (data) => {
    return {
        type: REDEEM_TICKET,
        data
    }
}

export const redeemTicket = (id) => {
    return async (dispatch) => {
        const data = await ticketsAPI.redeemTicket(id)
        dispatch(redeemTicketAC(data))
    }
}

const redeemTicketSendCodeAC = (data) => {
    return {
        type: REDEEM_TICKET_SEND_CODE,
        data
    }
}

export const redeemTicketSendCode = (id, code) => {
    return async (dispatch) => {
        const data = await ticketsAPI.redeemTicketSendCode(id, code)
        dispatch(redeemTicketSendCodeAC(data))
    }
}

const redeemTicketSaveAC = (data) => {
    return {
        type: REDEEM_TICKET_SAVED,
        data
    }
}

export const redeemTicketSave = (id, variationId) => {
    return async (dispatch) => {
        const data = await ticketsAPI.redeemTicketSave(id, variationId)
        if(data.result_code === 0) {
            dispatch(redeemTicketSaveAC(true))
        }
    }
}

const redeemTicketStepTwoAC = data => {
    return {
        type: REDEEM_STEP_TWO,
        data
    }
}

export const redeemTicketStepTwo = (id, variationId) => {
    return async (dispatch) => {
        const data = await ticketsAPI.redeemTicketStepTwo(id, variationId)
        dispatch(redeemTicketStepTwoAC(data))
    }
}

const redeemTicketStepThreeAC = data => {
    return {
        type: REDEEM_STEP_THREE,
        data
    }
}

export const redeemTicketStepThree = (id, variationId, code) => {
    return async (dispatch) => {
        const data = await ticketsAPI.redeemTicketStepThree(id, variationId, code)
        dispatch(redeemTicketStepThreeAC(data))
    }
}

export default activateReducer

