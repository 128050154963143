import React from 'react'
import { Table, Tag, Space } from 'antd'

const CustomTable = (props) => {
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Номер билета',
            dataIndex: 'unique_id',
            key: 'unique_id',
        },
        {
            title: 'Название',
            dataIndex: 'ticket_title',
            key: 'ticket_title',
        },
        {
            title: 'Сумма',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Дата',
            dataIndex: 'created_at',
            key: 'created_at',
        }
    ]

    return <Table
        dataSource={props.data}
        columns={columns}
        pagination={props.pagination}
        rowKey="id"
    />
}

export default CustomTable