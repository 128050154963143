import React from 'react';
import {Descriptions, Divider, Table} from "antd";
import {Link} from "react-router-dom";

const Executor = (props) => {
    props = props.data;

    const columns = [
        {
            title: 'key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ID заказа',
            dataIndex: 'unique_id',
            key: 'unique_id',
            render: (text, record) => <Link to={'/ticket/' + record.unique_id}>{record.unique_id}</Link>
        },
        {
            title: 'Название',
            dataIndex: 'prod_title',
            key: 'prod_title',
        },
        {
            title: 'Дата гашения',
            dataIndex: 'created_at',
            key: 'created_at',
        },
    ];

    props.activations.map((item, key) => {
        item.key = key + 1
    })

    return <div style={{ padding: "0px 20px 20px 20px" }}>
        <Divider />
        <Descriptions style={{ marginTop: 10 }} title="Информация о исполнителе">
            <Descriptions.Item label="ID">{props.id}</Descriptions.Item>
            <Descriptions.Item label="Имя">{props.name}</Descriptions.Item>
            <Descriptions.Item label="Фамилия">{props.surname}</Descriptions.Item>
            <Descriptions.Item label="Отчество">{props.patronymic}</Descriptions.Item>
            <Descriptions.Item label="Email">{props.email}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <h2>Погашенные услуги</h2>
        <Table dataSource={props.activations} columns={columns} />
    </div>
};

export default Executor;