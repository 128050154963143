import React, {useEffect, useState} from 'react'
import s from './Navbar.module.scss'
import {Dropdown, Layout, Menu, message} from 'antd'
import {connect} from "react-redux"
import {loginAC, logout} from "../../redux/authReducer"
import { MenuOutlined, DownOutlined } from '@ant-design/icons'
import LoginContainer from "../Login/LoginContainer"
import {Link, NavLink, Redirect} from "react-router-dom"
import logo from "./../../images/logo.svg"
import LoginModal from "../Login/LoginModal/LoginModal";
const { Header } = Layout


const Navbar = (props) => {

    const [redirectToLogin, isRedirectToLogin] = useState(false)
    const [redirectToProfile, setRedirectToProfile] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)

    const logout = () => {
        props.logoutUser()
        //isRedirectToLogin(true)
    }

    const profile = () => {
        setRedirectToProfile(true)
    }

    const menu = (
        <Menu>
            <Menu.Item key="1"><NavLink to={'/profile'}>Профиль</NavLink></Menu.Item>
            <Menu.Item key="2" onClick={logout}>Выход</Menu.Item>
        </Menu>
    )

    if(redirectToLogin) {
        return <Redirect to={'/login'} />
    }

    if(redirectToProfile) {
        return <Link to={{ pathname: '/profile' }} />
    }

    return <>
        <LoginModal isModalVisible={showLoginModal} handleCancel={() => {setShowLoginModal(false)}}/>
        <Header className={s.header}>
        <div className={s.left}>
            {props.auth.isAuth &&
                <div className={s.menu}>
                    <MenuOutlined className={s.sidebar_mobile_icon} onClick={props.openSidebar}/>
                </div>
            }
            <div className={s.logo}>
                <a href={'https://dreambox.by'} target="_blank"><img src={logo} /></a>
            </div>
        </div>
        <div className={s.additional}>
            {props.auth.isAuth ?
                <Dropdown overlay={menu}>
                    <a className={`ant-dropdown-link ${s.navbar_link_style}`}>
                        <div>
                            <span>{props.auth.name}</span>
                            <span>{props.auth.status_ru}</span>
                        </div>
                        <DownOutlined />
                    </a>
                </Dropdown>
                :

                <a onClick={() => { setShowLoginModal(true)}} className={`ant-dropdown-link ${s.navbar_link_style}`}>
                    Вход
                </a>
            }
        </div>
    </Header>
    </>
}

let mapDispatchToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapDispatchToProps, {
    logoutUser: logout
})(Navbar)