import React, {useEffect} from 'react'
import {connect} from "react-redux"
import {getExecutorsThunk} from "../../redux/executorsReducer"
import {setSidebarItem} from "../../redux/sidebarReducer"
import {Button, PageHeader, Popconfirm, Spin, Table, Tag, Tooltip} from "antd"
import ExecutorsTable from "../Executors/Executors"
import {editReportsData, getAllReports, reportTogglePayment} from "../../redux/reportReducer"
import Spinner from "../common/Spinner/Spinner";

const ReportsContainer = props => {
    useEffect(() => {
        props.setSidebar('reports')
        props.getReports()
    }, [])

    const togglePayment = reportId => {
        props.reportTogglePayment(reportId)
        props.editReportsData(reportId)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Созадтель',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Сумма',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Отчет',
            dataIndex: 'link',
            key: 'link',
            render: (text, record) => <a target={'_blank'} href={record.link}><Button type="primary" size={"small"}>Скачать отчет</Button></a>
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (text === 'Не оплачено') ?
                <Tag color="#f50">Не оплачено</Tag> :
                <Tooltip placement="top" title={`Дата выплаты: ${record.payment_date}`}>
                    <Tag color="#33a502">Оплачено</Tag>
                </Tooltip>
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (record.status === 'Не оплачено') ?
                <Popconfirm
                    title={`Вы уверены что хотите сменить статаус на "Оплачен"?`}
                    onConfirm={() => togglePayment(record.id)}
                    okText="Сменить"
                    cancelText="Отмена"
                    style={{maxWidth: '300px'}}
                    placement="top"
                >
                    <a href="#">Оплачен</a>
                </Popconfirm> :
                <Popconfirm
                    title={`Вы уверены что хотите сменить статаус на "Не оплачен"?`}
                    onConfirm={() => togglePayment(record.id)}
                    okText="Сменить"
                    cancelText="Отмена"
                    style={{maxWidth: '300px'}}
                    placement="top"
                >
                    <a href="#">Не оплачено</a>
                </Popconfirm>
        }
    ]


    if(props.reports_loading) {
        return <Spin />
    }

    return <>
        <PageHeader
            className="site-page-header"
            ghost={false}
            title="Отчеты"
        />
        <Table
            columns={columns}
            dataSource={props.reports}
        ></Table>
    </>
}

let mapDispatchToProps = (state) => {
    return {
        reports: state.report.reports,
        reports_loading: state.report.reports_loading
    }
}

export default connect(mapDispatchToProps, {
    setSidebar: setSidebarItem,
    getReports: getAllReports,
    reportTogglePayment: reportTogglePayment,
    editReportsData: editReportsData
})(ReportsContainer)