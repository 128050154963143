import {ticketsAPI} from "../api/api"

const GET_PROFILE = 'GET_PROFILE'

let initialState = {
    profile: []
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE: {
            return {
                ...state,
                profile: action.profile
            }
        }
        default:
            return state
    }
}

let getProfileAC = (profile) => {
    return {
        type: GET_PROFILE,
        profile
    }

}

export const getProfile = (id = null) => {
    return async (dispatch) => {
        const userId = id ? id : ''
        const data = await ticketsAPI.getUser(userId)
        dispatch(getProfileAC(data))
    }
}

let getMyProfileAC = (profile) => {
    return {
        type: GET_PROFILE,
        profile
    }
}

export const getMyProfile = (userId) => {
    return async (dispatch) => {
        const data = await ticketsAPI.getMyProfile(userId)
        dispatch(getMyProfileAC(data))
    }
}

export const setUserFee = (userId, fee) => {
    return async dispatch => {
        await ticketsAPI.setUserFee(userId, fee)
    }
}

export default profileReducer

