import React, {useEffect, useState} from 'react'
import {Modal, Button, Spin, Form, Input, Alert, Select, Checkbox, Row, Col, Steps, Radio, Space, message} from 'antd'
import s from './RedeemTicket.module.scss'
import {connect} from "react-redux"
import {
    redeemTicket,
    redeemTicketSave
} from "../../../redux/activateReducer";

const RedeemTicket = props => {
    const [selectedVariationId, setSelectedVariationId] = useState()

    const stepOne = () => {
        if(!selectedVariationId) {
            return message.error('Ни одной услуги не выбрано')
        }
        props.redeemTicketSave(props.ticketId, selectedVariationId).then(() => {
            props.hideRedeemModal()
            message.success('Услуга погашена')
            props.getTicket(props.ticketId)
        })
    }

    useEffect(() => {
        return props.redeemTicket(props.ticketId)
    }, [])

    console.log(props)

    return <>
        <Modal title="Погасить программу"
               visible={props.isModalVisible}
               onCancel={props.hideRedeemModal}
               onOk={props.showRedeemModal}
               cancelText="Отмена"
               footer={null}
        >

            <div className={s.activate_wrapper}>
                {!props.activateOptions.data && <div className={s.spinner}>
                        <Spin />
                    </div>
                }

                {props.activateOptions.result_code === 1 &&
                    <h2>Нет доступных услуг для активации</h2>
                }

                <Form onFinish={stepOne}>
                    <Radio.Group name="radiogroup">
                     
                        { props.activateOptions.data &&
                        props.activateOptions.data.map(item => {
                            if(item.hidden === 0) {
                                return <Radio value={item.variation_id} key={item.variation_id}
                                   disabled={item.used}
                                   onChange={() => setSelectedVariationId(item.variation_id)}>
                                {item.title}
                                </Radio>
                            }
                        })
                        }

                    </Radio.Group>

                    <Form.Item className={s.buttons}>
                        {props.activateOptions.result_code === 0 &&
                            <Button type="primary" htmlType="submit">
                                Погасить
                            </Button>
                        }
                    </Form.Item>
                </Form>

            </div>
        </Modal>
    </>
}

let mapDispatchToProps = (state) => {
    return {
        activateOptions: state.activate.redeemTicket,
        activateOptionsSendCode: state.activate.redeemTicketWithCode,
        redeemTicketSaved: state.activate.redeemTicketSaved,    
    }
}

export default connect(mapDispatchToProps, {
    redeemTicket: redeemTicket,
    redeemTicketSave: redeemTicketSave
})(RedeemTicket)
