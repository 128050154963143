import React, {useState} from 'react'
import {Alert, Button, Form, Input, Modal} from "antd";
import {connect} from "react-redux";
import {getAuthUserData, loginAC} from "../../../redux/authReducer";
import s from "../../ActivateTicket/ActivateModal/ActivateModal.module.scss";
import LoginContainer from "../LoginContainer";

const LoginModal = (props) => {

    const [error, setError] = useState(false)

    const onFinish = (data) => {
        setError(false)
        props.login(data.username, data.password).then(() => {
            props.getAuthUserData()
        })
    }

    const onFinishFailed = (data) => {
        setError(true)
    }

    return <Modal title="Авторизация"
           visible={props.isModalVisible}
           // onOk={props.handleCancel}
           onCancel={props.handleCancel}
           cancelText="Отмена"
           footer={null}
    >
        <LoginContainer forModal={true} closeModal={props.handleCancel} />
    </Modal>
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth
})

export default connect(mapStateToProps, {
    login: loginAC,
    getAuthUserData: getAuthUserData
})(LoginModal)