import React, {useState} from 'react'
import {
    Button,
    Card,
    Col,
    Descriptions,
    PageHeader,
    Row,
    Segmented,
    Spin,
    Table,
    Tag,
    EditOut,
    Radio,
    Tooltip
} from "antd"
import s from './Profile.module.scss'
import CustomTable from "./CustomTable/CustomTable"
import Report from "../Executor/Report/Report";
import {Link} from "react-router-dom";
import { EditOutlined } from '@ant-design/icons';
import SetFeeModal from "./SetFeeModal/SetFeeModal";
import ReportTemplateModal from "./ReportTemplateModal/ReportTemplateModal";

const Profile = props => {
    const [showReportModal, setShowReportModal] = useState(false)
    const [currentProfileTab, setCurrentProfileTab] = useState('История')
    const [showFeeModal, setShowFeeModal] = useState(false)
    const [showReportTemplateModal, setShowReportTemplateModal] = useState(false)

    if(!props.data.email) return <Spin />

    const RenderCurrentTab = () => {
        switch(currentProfileTab) {
            case 'История':
                return <>
                    <h2 style={{marginTop: '20px'}}>История гашения билетов</h2>
                    <CustomTable pagination={false} data={props.data.history}/>
                </>
            case 'Отчеты':
                const columns = [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                    },
                    {
                        title: 'Название',
                        dataIndex: 'title',
                        key: 'title'
                    },
                    {
                        title: 'Сумма',
                        dataIndex: 'total',
                        key: 'total',
                    },
                    {
                        title: 'Отчет',
                        dataIndex: 'link',
                        key: 'link',
                        render: (text, record) => <a target={'_blank'} href={record.link}><Button type="primary" size={"small"}>Скачать отчет</Button></a>
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text, record) => (text === 'Не оплачено') ? <Tag color="#f50">Не оплачено</Tag> : <Tag color="#87d068">Оплачено</Tag>
                    },
                ]

                return <>
                    <h2 style={{marginTop: '20px'}}>Отчет</h2>
                    <Table
                        dataSource={props.data.reports}
                        columns={columns}
                    ></Table>
                </>
            case 'Доступные услуги':
                return <>
                    <h2 style={{marginTop: '20px'}}>Доступные услуги</h2>
                    <Row gutter={24} className={s.available_products}>
                        <Col span={24}>
                            {props.data.available_products.map((item, index) => {
                                return <Card type="inner" key={index} title={`${item.title} (#${item.variation_id})`} bordered={true}>
                                    Цена: {item.price} BYN
                                </Card>
                            })}
                        </Col>
                    </Row>
                </>
            default:
                return <div style={{color: 'white'}}>1</div>;
        }
    }

    return <>
        <ReportTemplateModal
            isModalVisible={showReportTemplateModal}
            handleCancel={() => setShowReportTemplateModal(false)}
            handleOk={() => setShowReportTemplateModal(true)}
            template={props.data.template.title}
            updateProfile={props.updateProfile}
            userId={props.userId}
        />
        <SetFeeModal
            isModalVisible={showFeeModal}
            handleCancel={() => setShowFeeModal(false)}
            handleOk={() => setShowFeeModal(true)}
            userId={props.userId}
            updateProfile={props.updateProfile}
            fee={props.data.fee ? props.data.fee : 0}
        />
        <Report isModalVisible={showReportModal}
                onOk={() => setShowReportModal(true)}
                handleCancel={() => setShowReportModal(false)}
                updateProfile={props.updateProfile}
                isLoading
        />
        <PageHeader
            title="Профиль"
            extra={[
                props.data.template.available ?
                    <Button onClick={() => setShowReportModal(true)}>Отчет</Button> :
                    <Tooltip title={'Отчеты заблокированы. Обратитесь к администратору.'}><Button onClick={() => setShowReportModal(true)} disabled={true}>Отчет</Button></Tooltip>
            ]}
        />
        <div className={s.content}>
            <div className={s.main_info}>
                <div className={s.logo}>
                    {props.data.name[0]}{props.data.surname[0]}
                </div>
                <div className={s.description}>
                    {props.data.name} {props.data.surname} {props.data.patronymic}
                </div>
            </div>

            <Descriptions style={{ marginTop: 30 }} title="Информация">
                <Descriptions.Item label="Процент комиссии">
                    {props.data.fee ? props.data.fee + '%' : 'Комиссия не установлена' }
                    {props.status === 'admin' ? <div style={{marginLeft: '10px'}}><EditOutlined onClick={() => setShowFeeModal(true)} /></div> : null}
                </Descriptions.Item>
                {props.status === 'admin' &&
                    <Descriptions.Item label="Шаблон отчета">
                        {props.data.template.title}
                        <div style={{marginLeft: '10px'}}><EditOutlined onClick={() => setShowReportTemplateModal(true)} /></div>
                        {!props.data.template.file_exist ?
                            <Tag color="red" style={{marginLeft: '10px'}}>Файл не найден</Tag>
                        :
                            <a target={"_blank"} href={props.data.template.link}>
                                <Button size="small" style={{marginLeft: '10px'}}>Открыть шаблон</Button>
                            </a>
                        }
                    </Descriptions.Item>
                }
                <Descriptions.Item label="Email">{props.data.email}</Descriptions.Item>
                <Descriptions.Item label="Статус">{props.data.status_ru}</Descriptions.Item>
            </Descriptions>

            <Segmented
                options={['История', 'Отчеты', 'Доступные услуги']}
                size="default"
                style={{marginTop: '15px'}}
                onChange={(tab) => setCurrentProfileTab(tab)}
            />

            <RenderCurrentTab />
        </div>
    </>
}

export default Profile